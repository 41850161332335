import {AppTheme} from '@reedsy/studio.shared/store/modules/user-settings/app-theme';
import {enumValues} from '@reedsy/utils.enum';
import SASS from '@reedsy/studio.shared/utils/sass';

const MEDIA_QUERY_MAPPING = {
  [AppTheme.Dark]: 'dark',
  [AppTheme.Light]: 'light',
} as const satisfies Record<AppTheme, string>;

export function setThemeColorMetaTag(theme: AppTheme): void {
  removeAllThemeColorTags();

  if (theme) return addThemeColorTag({theme});

  enumValues(AppTheme)
    .forEach((theme) => addThemeColorTag({theme, withMediaQuery: true}));
}

function removeAllThemeColorTags(): void {
  const themeColorMetaTags = document.querySelectorAll('meta[name=theme-color]');
  themeColorMetaTags.forEach((tag) => tag.remove());
}

function addThemeColorTag({theme, withMediaQuery}: {theme: AppTheme; withMediaQuery?: boolean}): void {
  const mediaQuery = `(prefers-color-scheme: ${MEDIA_QUERY_MAPPING[theme]})`;

  const themeColorMetaTag = (
    <meta
      name="theme-color"
      content={SASS.mainThemeColors[theme]}
      media={withMediaQuery ? mediaQuery : null}
    />
  );

  document.head.append(themeColorMetaTag);
}
