<template>
  <div class="endnote-pane-wrapper">
    <transition name="fade">
      <Overlay
        v-if="show"
        class="overlay"
        @click="close"
      />
    </transition>
    <transition name="endnote-pane">
      <div
        v-if="show"
        class="endnote-pane"
      >
        <div
          id="endnote-pane-content"
          class="endnote-pane-content"
        >
          <div class="endnote-controls">
            <div class="endnote-number">
              <span v-if="endnoteNumber">
                {{ endnoteNumber }}.
              </span>
            </div>
            <button
              ref="closeButton"
              class="close-endnote-pane-button reedsy-accented"
              type="button"
              @click="close"
            >
              <div
                v-if="readOnly"
                class="close-button"
              >
                Close
                <div class="close-icon">
                  <VuiIconCross class="icon-sm" />
                </div>
              </div>
              <div
                v-else
                class="close-button save"
              >
                Save
                <div class="close-icon">
                  <VuiIconTick class="icon-sm" />
                </div>
              </div>
            </button>
          </div>
          <div
            id="endnote-container"
            class="endnote-container theme-body editor-wrapper"
          >
            <slot name="endnote-editor" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import Overlay from '@reedsy/studio.shared/components/overlay.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    Overlay,
  },
})
export default class EndnotePaneWrapper extends ClientSharedVue {
  @Prop({type: Number})
  public endnoteNumber: number;

  @Prop({type: Boolean, required: true})
  public show: boolean;

  @Prop({type: Boolean, default: false})
  public readOnly: boolean;

  public close(): void {
    this.$router.push({hash: ''});
  }
}
</script>

<style lang="scss" scoped>
.endnote-pane-wrapper {
  $endnote-pane-transitions: (left);

  position: fixed;
  left: var(--left-sidebar-offset);
  right: 0;
  overflow: visible; // Must be visible so the toolbar can "pop out"
  z-index: $z-index-endnote-pane;

  @include transition(list.append($endnote-pane-transitions, right));

  .with-suggestion-pane & {
    right: var(--suggestions-pane-offset);
  }

  @include screen-more-than(xs) {
    bottom: 0;
  }

  @include screen-less-than(sm) {
    top: 3rem;
  }

  .no-right-sidebar-transitions & {
    @include transition($endnote-pane-transitions);
  }
}

.overlay {
  @include screen-more-than(xs) {
    display: none;
  }
}

.endnote-pane {
  background: var(--reedsy-plain);
  flex-direction: column;
  padding: $space-lg $view-margin;
  box-shadow: $box-shadow-up-base;
  position: relative;

  @include transition(all);

  @include screen-less-than(sm) {
    padding: $view-margin-mobile;

    $radius: $border-radius-base;

    box-sizing: border-box;
    display: flex;
    height: $endnotes-max-mobile-height;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    padding-top: 1rem;
  }

  .endnote-pane-content {
    display: flex;
    flex-direction: column;
    gap: $space-md;
    max-width: $view-max-width;
    max-height: safe-vh(40);
    min-height: 15rem;
    margin: auto;

    @include screen-less-than(sm) {
      flex: 1 1 auto;
      margin: 0;
      max-height: unset;
    }
  }

  .endnote-container {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;

    @include screen-less-than(md) {
      padding: 0;
    }

    :deep(.rich-text-editor) {
      flex: 1 1 auto;
    }
  }

  .endnote-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .endnote-number {
    flex: 0 0 2rem;

    @include font-family($controls, bold);
  }

  .close-button {
    display: flex;
    align-items: center;

    @include transition(color);

    .close-icon {
      @include size(1.5rem);
      @include transition((background, border));

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: $space-xxs solid var(--reedsy-neutral);
      box-sizing: border-box;
      margin-left: $space-sm;

      .icon {
        transform: scale(0.75);
      }
    }

    &:hover {
      .close-icon {
        border-color: var(--reedsy-neutral-emphasis);
      }
    }

    &.save {
      color: var(--local-color-default);

      &:hover {
        color: var(--local-color-emphasis);

        .close-icon {
          color: var(--local-color-onEmphasis);
          background: var(--local-color-emphasis);
        }
      }

      .close-icon {
        background: var(--local-color-default);
        border: none;
        color: var(--local-color-onDefault);
      }
    }
  }

  :deep(.ql-editor) {
    padding-top: $space-sm;
    padding-bottom: 6rem; // Clearance for the widgets
    padding-right: $space-base;
  }
}
</style>
